
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { reactive } from "vue";

export default defineComponent({
  data() {
    return {
      hideBackground: false,
      dataTr: [
        { id: 1, t1: "数字婚嫁", ent1: "Marriage" },
        { id: 2, t1: "数字业财税", ent1: "Fnance and taxation" },
        { id: 3, t1: "数字零售", ent1: "Retail" },
        { id: 4, t1: "数字办公", ent1: "Official" },
        { id: 5, t1: "数字权益", ent1: "Rights and interests" },
        { id: 6, t1: "云产品", ent1: "Cloud" },
        { id: 7, t1: "数字服务", ent1: "Service" },
      ],
      data: [
        {
          id: 1,
          col1: "婚嫁互娱",
          col2: "进销存云",
          col3: "社区团购",
          col4: "MK数字工作平台",
          col5: "商城权益",
          col6: "储存",
          col7: "电子名片",
        },
        {
          id: 2,
          col1: "婚礼管家",
          col2: "财务云",
          col3: "餐饮外卖",
          col4: "中大型数字化OA",
          col5: "礼品卡权益",
          col6: "数据库",
          col7: "聚合支付",
        },
        {
          id: 3,
          col1: "婚礼请柬",
          col2: "生产云",
          col3: "水产生鲜",
          col4: "中小型数字化OA",
          col5: "会员体系",
          col6: "弹性计算",
          col7: "",
        },
        {
          id: 4,
          col1: "婚礼电子名片",
          col2: "电商云",
          col3: "电商商城",
          col4: "信创OA",
          col5: "",
          col6: "安全",
          col7: "",
        },
        {
          id: 5,
          col1: "",
          col2: "零售云",
          col3: "",
          col4: "业务管理云OA",
          col5: "",
          col6: "网络与CDN",
          col7: "",
        },
        {
          id: 6,
          col1: "",
          col2: "订货云",
          col3: "",
          col4: "KMS知识管理平台",
          col5: "",
          col6: "",
          col7: "",
        },
        {
          id: 7,
          col1: "",
          col2: "税务云",
          col3: "",
          col4: "",
          col5: "",
          col6: "",
          col7: "",
        },
        {
          id: 8,
          col1: "",
          col2: "数据服务云",
          col3: "",
          col4: "",
          col5: "",
          col6: "",
          col7: "",
        },
      ], // 后台传递的数据
    };
  },
  created() {
    // 在组件创建时添加路由更新守卫
    onBeforeRouteUpdate((to, from, next) => {
      // 检查要访问的路由路径是否匹配您想要隐藏背景图像的路径
      if (to.path === "/activityinfo/activityinfo1") {
        this.hideBackground = true;
      } else {
        this.hideBackground = false;
      }
      next();
    });
  },
  setup(props, context) {
    const navIndex = ref(0);
    const router = useRouter();
    const state = reactive({
      navList: [
        {
          value: "home",
          label: "首页",
          enLabel: "HOME PAGE",
          path: "/",
          image: {
            src: "../../../public/img/logo/nav_bo.png",
            alt: "Home Icon",
          },
        },
        { value: "product", label: "产品&服务", enLabel: "PRODUCTS", path: "" },
        { value: "solution", label: "解决方案", enLabel: "SOLUTION", path: "" },
        // {
        //   value: "cooperative",
        //   label: "合作商户",
        //   enLabel: "PARTNER",
        //   path: "/cooperative/cooperative_home",
        // },
        // {
        //   value: "investor",
        //   label: "投资者关系",
        //   enLabel: "INVESTOR",
        //   // path: "/invest/invest_home",
        //   path: "",
        // },
        {
          value: "aboutfd",
          label: "关于分蛋",
          enLabel: "ABOUT US",
          path: "/about/aboutfd_home",
        },
      ],
      // navIndex: 0,
      popupVisible: [false, false, false, false, false, false],
    });

    onMounted(() => {
      const _index = parseInt(sessionStorage.getItem("navIndex")!);
      console.log(_index, "我是获取的");
      navIndex.value = _index ? _index : 0;
    });

    //点击logo跳转首页
    const chooseNavLogo = (index: number) => {
      sessionStorage.setItem("navIndex", String(index));
      // 子页面时 导航栏跳转
      if (router.currentRoute.value.path !== "/") {
        router.push("/");
      }
      window.scrollTo(0, 0);
      navIndex.value = index;
      context.emit("getNav", index);
    };

    const chooseNav = (index: number) => {
      sessionStorage.setItem("navIndex", String(index));
      // 子页面时 导航栏跳转
      // if (router.currentRoute.value.path !== "/") {
      //   router.push("/");
      // }
      console.log(index, "我是第一次");
      window.scrollTo(0, 0);
      navIndex.value = index;
      context.emit("getNav", index);
      // if (index == 3) {
      //   window.open("https://int.dev-jd-a.91fd.tech/");
      // }
    };

    const showPopup = (index: number) => {
      // 根据当前悬停的菜单项的索引值来显示相应的弹窗
      if (index == 1) {
        const popup = document.querySelector(`#popup-${index}`);
        if (popup) {
          popup.classList.add("show");
        }
      }
      if (index == 2) {
        const popup2 = document.querySelector(`#popup2-${index}`);
        if (popup2) {
          popup2.classList.add("show");
        }
      }
      state.popupVisible.splice(index, 1, true);
    };

    const hidePopup = (index: number) => {
      // 根据当前悬停的菜单项的索引值来隐藏相应的弹窗
      const popup = document.querySelector(`#popup-${index}`);
      if (popup) {
        popup.classList.remove("show");
      }
      state.popupVisible.splice(index, 1, false);
    };

    const cancelHidePopup = () => {
      // 取消隐藏弹窗
      // 如果需要在弹窗中放置交互元素，可以使用这个方法来防止鼠标离开弹窗时立即隐藏弹窗
    };

    const toTop = () => {
      window.scrollTo(0, 0);
    };

    return {
      ...state,
      showPopup,
      hidePopup,
      cancelHidePopup,
      // navList,
      chooseNavLogo,
      navIndex,
      chooseNav,
      toTop,
    };
  },
});


import { defineComponent, ref } from "vue";
import Nav from "@/components/home/Nav.vue";
// import Footer from "@/components/home/Footer.vue";


export default defineComponent({
  components: {
    Nav,
    // Footer,
  },
  setup() {
    const navIndex = ref(0);

    const getNav = (current_nav: number) => {
      navIndex.value = current_nav;
    };

    return {
      navIndex,
      getNav,
    };
  },
});

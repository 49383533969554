import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06616cb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "back-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CaretTop = _resolveComponent("CaretTop")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Nav, { onGetNav: _ctx.getNav }, null, 8, ["onGetNav"]),
    _createVNode(_component_router_view),
    _createVNode(_component_el_backtop, {
      "visibility-height": 400,
      style: {"right":"0","width":"2.917rem","top":"34.067rem","min-width":"70px","bottom":"40px","padding-left":"8px"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_CaretTop)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}